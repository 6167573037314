import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
} from 'react-router-dom';

import './index.css';
import Admin from './pages/Admin';
import MeetingNew from './pages/MeetingNew';
import Login from './pages/Login';
import Vote from './pages/Vote';

import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Navigate to="/meeting/new" />,
    },
    {
        path: '/login',
        element: <Login />,
    },
    {
        path: '/meeting',
        //TODO don't know why this caused an infinite loop
        // element: <Navigate to="/meeting/new" />,
        children: [
            {
                path: 'new',
                element: <MeetingNew />,
            },
            {
                path: ':meetingId/vote',
                element: <Vote />,
            },
        ],
    },
    {
        path: '/admin',
        element: <Admin />,
    },
    // deprecating lol
    {
        path: '/export',
        element: <Navigate to="/admin" />,
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Link, useParams } from 'react-router-dom';

import { set, ref } from 'firebase/database';
import { throttle } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import JSConfetti from 'js-confetti';

import { db } from '../firebase';

import { XCircleIcon } from '@heroicons/react/24/solid';

import lilo from '../assets/lilo-happy.png';
import miso from '../assets/miso-neutral.png';
import muffin from '../assets/muffin-mad.png';

import liloBg from '../assets/lilo-bg.png';
import misoBg from '../assets/miso-bg.png';
import muffinBg from '../assets/muffin-bg.png';

import { VIBE_NEUTRAL, VIBE_SAD, VIBE_SMILE } from '../constants';

const DEBOUNCE_TIME = 3000;
const jsConfetti = new JSConfetti();

function Vote() {
    const { meetingId } = useParams();

    // write
    const writeToDb = throttle(
        (vibe) => {
            const uuid = uuidv4();
            let emojiVibe = '';

            set(ref(db, `/${uuid}`), {
                vibeId: uuid,
                meetingId: meetingId,
                userId: 1, //TODO: replace with actual user id, maybe email?
                timestamp: Date.now(),
                value: vibe,
            });

            switch (vibe) {
                case 1:
                    emojiVibe = '😒';
                    break;
                case 2:
                    emojiVibe = '🙂';
                    break;
                case 3:
                    emojiVibe = '😠';
                    break;
                default:
                    emojiVibe = '🧐';
            }

            jsConfetti.addConfetti({
                emojis: [emojiVibe],
                emojiSize: 150,
                confettiNumber: 75,
            });
        },
        DEBOUNCE_TIME,
        { leading: true },
    );

    return (
        <div className="Vote h-full">
            <header className="bg-white">
                <nav
                    className="mx-auto flex max-w-7xl items-center justify-between p-2 lg:px-8"
                    aria-label="Global"
                >
                    <h2 className="px-2 text-3xl font-bold tracking-tight text-gray-900 App-title">
                        what&rsquo;s the vibe?
                    </h2>
                    <div>
                        <Link to="/meeting/new">
                            <XCircleIcon className="h-6 w-6" />
                        </Link>
                    </div>
                </nav>
            </header>
            <section className="py-1">
                <p className="text-center text-sm text-gray-600">
                    <b>Meeting ID:</b> {meetingId}
                </p>
            </section>
            <section class="dog-container flex flex-col">
                <div
                    className="lilo-section cursor-pointer flex flex-1 items-center justify-between pr-5 w-full select-none"
                    onClick={() => {
                        writeToDb(VIBE_SMILE);
                    }}
                >
                    <div className="lilo">
                        <img src={lilo} alt="lilo" className="lilo-top z-10" />
                        <img
                            src={liloBg}
                            alt="lilo"
                            className="lilo-bottom z-0"
                        />
                    </div>
                    <p className="App-title font-bold text-white text-8xl lilo-text">
                        vibin&rsquo;
                    </p>
                </div>
                <div
                    className="miso-section cursor-pointer flex flex-1 items-center justify-between pr-5 select-none"
                    onClick={() => {
                        writeToDb(VIBE_NEUTRAL);
                    }}
                >
                    <div className="miso">
                        <img src={miso} alt="miso" className="miso-top z-10" />
                        <img
                            src={misoBg}
                            alt="miso"
                            className="miso-bottom z-0"
                        />
                    </div>
                    <p className="App-title font-bold text-white text-8xl miso-text">
                        meh
                    </p>
                </div>
                <div
                    className="muffin-section cursor-pointer flex flex-1 items-center justify-between pr-5 select-none"
                    onClick={() => {
                        writeToDb(VIBE_SAD);
                    }}
                >
                    <div className="muffin">
                        <img
                            src={muffin}
                            alt="muffin"
                            className="muffin-top z-10"
                        />
                        <img
                            src={muffinBg}
                            alt="muffin"
                            className="muffin-bottom z-0"
                        />
                    </div>
                    <p className="App-title font-bold text-white text-8xl muffin-text">
                        ugh
                    </p>
                </div>
            </section>
        </div>
    );
}

export default Vote;

import { useState, useEffect, useRef } from 'react';

import { CSVLink } from 'react-csv';
import { ref, get } from 'firebase/database';

import { db } from '../firebase';
import { VIBE_NEUTRAL, VIBE_SMILE, VIBE_SAD } from '../constants'

function Export({ meetingId }) {
    const [data, setData] = useState(false);
    const csvRef = useRef();

    // read database and set fetched data to state variable
    function readDb() {
        if (meetingId === '') {
            console.log('need to input meeting id to fetch data');
            return;
        }

        get(ref(db))
            .then((snapshot) => {
                const temp = [];
                if (snapshot.exists()) {
                    Object.values(snapshot.val()).forEach((vibe) => {
                        if (vibe.meetingId === meetingId) {
                            temp.push(vibe);
                        }
                    });
                    setData(temp);
                } else {
                    // @todo: maybe show a banner saying db is empty
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    // convert ms to yyyy-mm-dd hh:mm
    function getDateString(ms) {
        let msToDate = new Date(ms);
        
        // adjust time based on timezone
        const offset = msToDate.getTimezoneOffset()
        msToDate = new Date(msToDate.getTime() - (offset*60*1000))
    
        // example: 2023-03-07T21:55:51.507Z => 2023-03-07 21:55
        const [date, time] = msToDate.toISOString().split('T')
        const [hour, min] = time.split(':');
    
        return `${date} ${hour}:${min}`;
    }
    
    function formatData(data) {
        const dataMap = new Map();
        const result = [];

        // loop through database data and use map to memoize count for
        // each vibe value
        data.forEach(({timestamp, value}, index) => {
            const dateString = getDateString(timestamp);
            const valueObj = dataMap.get(dateString);
            let newEntry = {};

            // if not in map yet, add new entry with 0 for each value
            if(!valueObj){
                newEntry.neutral = 0;
                newEntry.happy = 0;
                newEntry.sad = 0;
            } else {
                // store all counts into temp variable
                 newEntry = {...valueObj};
            }
            // increment count
            switch(value) {
                case VIBE_NEUTRAL:
                    newEntry.neutral = newEntry.neutral+1;
                    break;
                case VIBE_SMILE:
                    newEntry.happy = newEntry.happy+1;
                    break;
                case VIBE_SAD:
                    newEntry.sad = newEntry.sad+1;
                    break;
                default:
                    // do nothing
            }

            // set entry with new count
            dataMap.set(dateString, newEntry)
        })

        // loop through map and massage data into new format
        for(let [key, value] of dataMap){
            result.push({
                timestamp: key,
                happy: value.happy,
                neutral: value.neutral,
                sad: value.sad
            })
        }
        return result;
    }

    const headers = [
        { label: 'Timestamp', key: 'timestamp' },
        { label: 'Happy', key: 'happy' },
        { label: 'Neutral', key: 'neutral' },
        { label: 'Sad', key: 'sad' },
    ];

    useEffect(() => {
        if (data && csvRef.current && csvRef.current.link) {
            csvRef.current.link.click();
        }
    }, [data]);

    return (
        <div className="Export mt-4">
            <button
                className="flex w-full justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                type="button"
                onClick={readDb}
            >
                <span> Export CSV </span>
            </button>
            {data ? (
                <CSVLink
                    data={formatData(data)}
                    headers={headers}
                    filename={`vibeamp_export_${meetingId}_${Date.now()}`}
                    ref={csvRef}
                    className="hiddenExcel"
                    target="_blank"
                />
            ) : undefined}
        </div>
    );
}

export default Export;

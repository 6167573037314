import { useEffect, useState } from 'react';

import { ref } from 'firebase/database';
import { useList } from 'react-firebase-hooks/database';
import { isEqual, uniqWith } from 'lodash';

import { VIBE_NEUTRAL, VIBE_SAD, VIBE_SMILE } from '../constants';
import { db } from '../firebase';

function Stats({ meetingId }) {
    const [stats, setStats] = useState([]);
    const [snapshots, loading, error] = useList(ref(db));
    if (error) {
        console.error('firebase error!', error);
    }

    useEffect(() => {
        let neutral = 0;
        let sad = 0;
        let smile = 0;
        if (!loading) {
            // filter out to just this meeting id and also remove dupes
            const vibes = uniqWith(
                snapshots
                    .map((vibe) => vibe.val())
                    .filter((vibe) => vibe.meetingId === meetingId),
                isEqual,
            );

            vibes.forEach((vibe) => {
                switch (vibe.value) {
                    case VIBE_NEUTRAL:
                        neutral++;
                        break;
                    case VIBE_SAD:
                        sad++;
                        break;
                    case VIBE_SMILE:
                        smile++;
                        break;
                    default:
                        break;
                }
            });
            setStats([
                { name: 'vibin', stat: smile },
                { name: 'meh', stat: neutral },
                { name: 'ugh', stat: sad },
            ]);
        }
    }, [loading, meetingId, snapshots]);

    return (
        <div>
            <h3 className="text-base font-semibold leading-6 text-gray-900">
                Stats
            </h3>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                {stats.map((item) => (
                    <div
                        key={item.name}
                        className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
                    >
                        <dt className="truncate text-sm font-medium text-gray-500">
                            {item.name}
                        </dt>
                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                            {item.stat}
                        </dd>
                    </div>
                ))}
            </dl>
        </div>
    );
}

export default Stats;
